.create-powertabs-subscription {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
}

.new-template-checkbox-container {
  padding: 0 0 20px;
}

.create-container {
  display: flex;
  flex-direction: column;
  margin: 2.5%;
  gap: 10px;
  border: 2px solid black;
  padding: 10px;
  border-radius: 5px;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.missingText {
  color: red;
}

.powertabs-run-all {
  display: flex;
  flex-direction: row;
  grid-template-columns: auto auto;
  gap: 10px;
}

.global-emails{
  width: 100%;
}
