.account-dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0 !important;
  //margin-bottom: 15px;

  .uuid {
    margin-bottom: 0;
    font-size: 15px;
  }

  .button-close {
    padding: 0;
    margin-top: -30px;
  }
}

.bold {
  font-weight: bold;
}

.account-dialog-action {
  margin: 10px 15px;
}

.no-record-wrapper {
  display: flex;

  .no-record-label {
    width: 25%;
    display: block;
  }
}

.company-name {
  text-decoration: underline;
  //cursor: pointer;

  &:hover {
    color: #5e5e5e;
  }
}

.companies-table-header {
}