#period-release{

//.upload{
//  margin-right: 100px;
//  display: flex;
//  justify-content: space-around;
//}
  .score-card-combobox{
    width: 150px
  }

  .file-combobox{
    min-width: 440px;
  }

  .score-card-container{
    display: flex;
    justify-content: flex-end;
  }

  .upload{
    position: relative;
    display: flex;
    //margin-right: 400px;
    justify-content: center;
  }


    input[type="file"] {
      display: none;
    }
    .custom-file-upload {
      border-radius: 4px;
      font-weight: 400;
      font-size: 16px;
      border: 1px solid #ccc;
      display: inline-block;
      padding: 6px 12px;
      cursor: pointer;

      &:hover{
        border: 1px solid black;
      }
    }

  .email-container {
    width: 80%;
    margin: 0 auto;
    padding-top: 20px;
  }

}