
#data-subscription {

  .dropdown {
    width: 100%;
  }

  .save-button {
    width: 50%;
  }

}
