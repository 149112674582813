.title-bar-container {
  top: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  order: 0;
  margin: 4px 16px;
  width: 100%;
  height: 40px;

  .header-button {
    cursor: pointer;
    font-weight: bold;
    padding: 8px 16px;
    width: 100px;
    height: 40px;
    float: right;
  }
}

.header-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.dashboard-title {
  width: 87px;
  height: 22px;
  font-style: normal;
  font-weight: 300;
  color: white;
  font-size: 18px;
  line-height: 22px;
  margin-left: 8px;
}

.nav-bar {
  border-bottom: 1px solid #D9D9D9;
}

.dropdown-menu-link-small {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #252525 !important;
  //background: #fefefe;
  padding: 0.7rem 1rem;

  &:hover {
    background-color: #F2F2F2;
  }
}

.is-dropdown-submenu{
  z-index: 2;
}

//.menu, .is-active:hover, .js-dropdown-active:hover{
//  transition: 0s;
//}



.nav-bar-selections{
  display: inline-flex;
}

.dropdown-menu-link-large {
  font-style: normal;
  font-size: 16px;
  line-height: 40px;
  color: #000000;
  font-weight: bold;
}

.dropdown-menu-link-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.chevron-down {
  padding-left: 8px;
  width: 20px;
  height: 20px;
}

.divider {
  padding-right: 8px;
  margin: 12px 0 12px 8px;
  border-right: 1px solid #D9D9D9;
}

.is-dropdown-submenu{
  z-index: 2;
}


.separator {
  border-top: solid 1px rgba(0, 0, 0, 0.25);
}

.dashboard-title {
  width: 87px;
  height: 22px;
  font-style: normal;
  font-weight: 300;
  color: white;
  font-size: 18px;
  line-height: 22px;
  margin-left: 8px;
}

.nav {
  padding: 0 8px;
  width: 100%;
  display: flex;

  .i {
    float: left;
    cursor: pointer;
    color: #FFF;
    display: flex;
    text-decoration: none;
    font-size: 12px;
    margin-left: 80px;
    padding: 15px;
  }

  .link {
    color: #FFFFFF !important;
  }

  .link:hover {
    color: #000000 !important;
  }
}

.z-to-the-top, .react-datepicker-popper {
  z-index: 100;
}

.menu {
  .is-active > a {
    background-color: white;
    color: #666666;
  }
}

.active {
  color: #404f5d;
  font-weight: 800;
  text-decoration: underline;
  text-decoration-color: #404f5d;
}

.title-tag {
  color: white;
  border-radius: 20px;
  padding: 4px;
  text-decoration: none;
  font-size: 9px;
  background-color: darkturquoise;
  margin-left: 4px;
}
