.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1000;
  overscroll-behavior: contain;
}

.modal-button {
  margin: 8px;

  &.cancel {
    background-color: white;
    color: #0a0a0a;
    border: 1px solid lightgray;

  }
  &.action {
    background-color: lightgray;
    color: #0a0a0a;

  }
}

.ReactModal__Overlay {
  z-index: 100;
}

.ReactModal__Content{
  width: 700px;
  overflow-wrap: break-word;
}

.modal-content {
  overflow-y: scroll;
  max-height: 500px;
}

.auth-cell-container {
  display: inline-flex;

  .update-modal {
    margin-right: 16px;
  }
}

.permission-cell-container {
  display: inline-flex;

  .modal-margin-right {
    margin-right: 16px;
  }
}

.purple-button {
  background-color: rebeccapurple;
}
.green-button {
  background-color: darkolivegreen;
}