.MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.mid-market-button-group {
  display: flex;
  justify-content: right;
  gap: 8px;
}