.table-container {
  padding: 1rem;
  height: 100%;
  flex: 1;
}

.gcs-update {
  font-weight: bold;
  font-size: 18px;
  margin: 0 40px 16px 0;
}

.summary-table {
  display: inline-flex;

  table {
    width: 500px
  }
}

thead {
  border-bottom: 2px solid black;
  th {
    text-align: center;
    vertical-align: baseline;
  }
}

td {
  max-width: 500px;
  overflow-wrap: break-word;
  text-align: center;
}

.pagination {
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    padding: 8px;
  }

  input, select {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none;
    width: 150px;
  }

  span {
    display: inline-block;
    margin: 3px 10px;
    white-space: nowrap;
  }
}

.table {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.column-align-left {
  text-align: left;
}