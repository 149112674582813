.create-cns-subscription {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
}

.create-container {
  display: flex;
  flex-direction: column;
  margin: 2.5%;
  gap: 10px;
  border: 2px solid black;
  padding: 10px;
  border-radius: 5px;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.missingText {
  color: red;
}

.cns-run-all {
  display: flex;
  flex-direction: row;
  grid-template-columns: auto auto;
  gap: 10px;
}

.global-emails {
  width: 100%;
}

.spinning-loader {
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}

.admin-options {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.mapping-table-header {
  background-color: #e2e4e5;
}

.mapping-table-preview-cell {
  background-color: #d0e7b7;
}

.mapping-table-preview-header {
  background-color: #c5e1a5;
}

.mapping-table-header-button-add {
  background-color: #e2e4e5;
}

.admin-options-btn {
  margin-top: 30px;
  display: flex;
  gap: 10px;
}

.account-modal-form {
  display: grid;
  gap: 10px;
  margin-top: 20px;
}

.css-1h51icj-MuiAutocomplete-root {
  z-index: 1;
}

.white-text:hover, .white-text:focus {
  color: white;
}

.MuiDataGrid-overlayWrapper, .MuiDataGrid-overlayWrapperInner {
  min-height: 50px !important;
}

.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
  margin-bottom: 0;
}