.graphiql-container {
    height: 85vh;
}

.docExplorerResizer {
    height: unset !important;
}

.doc-explorer-title-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.history-title-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}