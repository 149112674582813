@import "../../node_modules/foundation-sites/scss/foundation";

@include foundation-everything;

@import '../../node_modules/react-tabs/style/react-tabs.scss';

.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 101;
}

.App-uat {
  background-color: darkgray;
}

.App-prod {
  background-color: black;
}

.App-dev {
  background-color: lightgray;
}

h1 {
  font-size: 24px;
  text-align: left;
  font-family: sans-serif;
  font-weight: bold;
  margin-bottom: 16px;
}

h2 {
  font-size: 16px;
  text-align: left;
  font-family: sans-serif;
  font-weight: bold;
  margin-bottom: 16px;
}

main {
  margin: 16px 36px;
  display: flex;
  flex-direction: column;
}


@import 'src/scss/react-table';
@import 'src/scss/header';
@import 'src/scss/page';
@import "src/scss/modal";
@import "src/scss/salesforceLogo";
@import "src/scss/scorecards";
@import "src/scss/PeriodRelease";
