
.form {
  margin-left: 50px;
  margin-top: 20px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  width: 30%;
}

.page-wrapper {
  height: 700px;
  display: flex;
}

.button-container {
  margin-left: 10px;
  margin-right: 10px;
}
